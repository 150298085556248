WebFont.load({
	google: {
		families: ['Ubuntu:300,400,400italic,500,700:latin']
	}
});

jQuery(document).ready(function($) {
	if ($("html").hasClass("lt-ie9")) {
		$(".columns div:first-child").css( "margin-left", "0" );
	}

	$(window).on('scroll', function() {
		var st = $(this).scrollTop();
		$('#emotion').css({
			'transform': 'translateY('+ (st/2) +'px)'
		});
	});

	if ($(window).width() < 935) {
		$('.nav_main').append($('.nav_meta li'));
	}
	$('#js_nav__mobile').prepend('<div class="navOpen"><input id="click" name="exit" type="checkbox"><label for="click"><span class="burger"></span></label></div>');
	$('#click').on('click', function() {
		$('.nav_main').slideToggle();
	});

	$('.nav_main').onePageNav({
		currentClass: 'current',
		changeHash: false,
		scrollSpeed: 750,
		scrollThreshold: 0.5,
		filter: '',
		easing: 'swing',
		begin: function() {
			//I get fired when the animation is starting
		},
		end: function() {
			//I get fired when the animation is ending
		},
		scrollChange: function($currentListItem) {
			//I get fired when you enter a section and I pass the list item of the section
		}
	});
});
